.big-old-container {
  border-radius: 16px;
  max-width: 1440px;
  margin: auto;
}

@media (hover: hover) {
  a.nolink:hover {
    color: black;
  }
}

a.nolink:active {
  color: black;
}

a.nolink {
  color: black;
}

.zero-available {
  /* Walt, use this */
  background-color: rgb(255, 234, 234) !important;
}

button.zero-available div p {
  color: #850707;
}

@media screen and (max-width: 500px) {
  button.zero-available {
    display: none !important;
  }
}

.cancel-booking-notice {
  background-color: rgb(58 126 210 / 11%);
  color: rgb(26 91 171);
  padding: 12px 13px;
  margin-top: 10px;
}

@media screen and (max-width: 1440px) {

  .big-old-container {
    margin: 1rem;
  }
}

@media screen and (max-width: 1024px) {
  .cancel-booking-notice.mobile {
    display: block !important;
  }

  .cancel-booking-notice.desktop {
    display: none;
  }
}

.cancel-booking-notice.mobile {
  display: none;
}

.cancel-booking-notice.desktop {
  padding: 10px 17px;
}

div.WalkSection {
  display: block;
  padding: 1.5rem 1rem;

  border: 1px solid lightgray;
  position: sticky;
  top: 0px;
  background-color: #fff;
}

div.WalkSection p {
  margin-top: 3px;
  color: #666;
}

div.WalkSection2 {
  display: block;
  padding: 1.5rem 1rem;
  border: 1px solid lightgray;
  background-color: #fff;
}

div.WalkSection2 p {
  margin-top: 3px;
  color: #666;
}

i.StupidWalkHack {
  font-weight: 400;
  font-style: normal;
}

.WalkSeeMore {
  width: 100%;
  padding: 1.2rem 1rem;
  font-size: 1.2rem;
  background-color: white;
  border: none;
  font-family: inherit;
  color: blue;
  border-bottom: 1px solid lightgray;
  border-left: 1px solid lightgray;
  border-right: 1px solid lightgray;
}

ol.HelpList {
  list-style: none;
  counter-reset: help-counter;
  padding-left: 0;
  margin-top: 10px;
}

ol.HelpList li {
  counter-increment: help-counter;
  position: relative;
  margin-bottom: 15px;
  padding-left: 32px;
}

ol.HelpList li::before {
  content: counter(help-counter);
  position: absolute;
  top: 0;
  left: 0;
  font-weight: bold;
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background: #e4eeff;
  color: #005eca;
  text-align: center;
  line-height: 24px;
  margin-right: 8px;
  margin-top: -2px;
}

@media (hover: hover) {
  button.WalkSeeMore:hover {
    background: #e4eeff;
    cursor: pointer;
    background: #e4eeff;
    cursor: pointer;
  }
}

ul.NoticeList {
  list-style: none;
  padding-left: 0;
}

ul.NoticeList li {
  margin-bottom: 20px;
}

.social-container {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.WalkItem svg {
  margin-left: 4px;
}

.WalkInTypes {
  margin-top: 10px;
  border-radius: 14px;
  border: 1px solid #b9dacc;
  width: fit-content;
  overflow: hidden;
}

.WalkInTypes h3 {
  background-color: #e6f2ed;
  color: #006138;
  padding: 0.5rem 1rem;
}

.WalkInTypes h3 svg {
  margin-right: 10px;
}

div.WalkInTypes p {
  padding: 0.5rem 0.5rem;
  margin: 0 0.5rem;
  font-size: 1rem;
}

div.WalkInTypes p:not(:first-of-type) {
  border-top: 1px solid #b9dacc;
}

.WalkModal section {
  margin-bottom: 0.5rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  line-height: 1.5;
}

.WalkModal section p {
  font-size: 1.1rem;
}

.WalkModal section:not(:last-child) {
  border-bottom: 1px solid lightgray;
}

[data-baseweb="select"] input[aria-hidden="true"] {
  display: none;
}

ul.af_list.af_list {
  /* this is pretty atrocious, but the element is positioned at root so we have to calculate the width manually */
  width: calc(min(100vw - 80px, 600px));
  box-sizing: border-box;
  z-index: 1501;
}

ul.af_list.af_list li.af_item {
  padding: 9px 13px;
  font-size: 1rem;
}

.not-bmv {
  display: block;
  background-color: #e6f2ed;
  border: 2px solid #b9dacc;
  font-weight: 500;
  border-radius: 14px;
  color: #006138;
  padding: 0.6rem 1rem;
  margin-top: 0.8rem;
}

.address-card {
  border: 2px solid lightgray;
  padding: 1rem;
  border-radius: 8px;
  display: block;
  margin: 1.5rem 0 0.5rem 0;
  display: flex;
  gap: 1rem;
  color: rgb(26 91 171);
}

.address-card:hover {
  background-color: #e4eeff;
  cursor: pointer;
}

.address-card section {
  padding: 0;
  margin: 0;
}

.address-card p {
  padding: 0;
  font-weight: 500;
  color: black;
}

.address-icon {
  font-size: 2rem;
  align-self: center;
}

.address-header {
  margin-top: 2.5rem;
  border-bottom: 1px solid lightgray;
  padding-bottom: 0.5rem;
}

.mappymap {
  border-bottom: 1px solid lightgray;
  position: sticky;
  top: 0;
  display: block;
}

.styled-tab {
  font-family: inherit;
  appearance: none;
  outline: none;
  border: none;
  border-radius: 0;
  margin: 0;
  cursor: pointer;
  background: none;
  font-size: 1.2rem;
  font-weight: 400;
  text-align: center;
  margin: 0px;
  width: 100%;
  padding: 13px 0px;
  border-bottom: 1px solid lightgray;
  color: #0059be;
  transition: all 0.15s;
}

.styled-tab:disabled {
  color: #333;
  cursor: not-allowed;
}

.styled-tab:disabled:hover {
  background-color: white;
}

.styled-tab:hover {
  background-color: #e4eeff;
}

.styled-tab[aria-selected="true"] {
  color: #000;
  border-bottom-color: #000;
  background-color: white;
  cursor: default;
  font-weight: 600;
}

.styled-tabs {
  background: white;
  border-left: 1px solid lightgray;
  border-right: 1px solid lightgray;
  display: flex;
  margin-bottom: 1.5rem;
  border-top: 1px solid lightgray;
}

@media screen and (max-width: 400px) {
  .styled-tabs {
    flex-direction: column;
  }
}

.styled-tabs a {
  display: block;
  flex: 1 0 auto;
}

.styled-tabs a :first-child {
  border-right: 1px solid lightgray;
}

.american-globe {
  height: 24px;
  align-content: "center";
  margin-left: 8px;
  width: 1em;
  margin-bottom: -5px;
}

@media screen and (max-width: 768px) {
  .mappymap {
    display: none;
  }
}

.header-main {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
  top: -2px;
  padding: 1.5rem 1rem;
  background-color: white;
  gap: 2rem;

  border: 1px solid lightgray;
  border-bottom: 0px;
}

@media screen and (max-width: 1024px) {
  .header-main {
    position: relative;
    top: initial;
    flex-direction: row;
  }
}

@media screen and (max-width: 768px) {
  .header-main {
    flex-direction: column;
  }
}

@media screen and (max-width: 500px) {
  .header-main {
    gap: 1rem;
    flex-direction: column;
  }
}

.header-main>div {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  max-height: 48px;
  width: auto;
  align-self: center;
}

@media screen and (max-width: 1024px) {
  .header-main>div {
    max-height: auto;
    flex-direction: row;
  }
}

@media screen and (max-width: 768px) {
  .header-main>div {
    flex-direction: column;
    width: 100%;
    max-height: initial;
  }
}

@media screen and (max-width: 500px) {
  .header-main>div {
    flex-direction: column;
  }
}

.header-main h1 {
  text-align: left;
  margin: 0;
  align-self: center;
  font-size: 2rem;
  font-weight: 600;
}

@media screen and (max-width: 768px) {
  .header-main h1 {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 500px) {
  .header-main section p {
    padding: 0.5rem 0 1rem 0;
  }
}

.header-main>section {
  flex-direction: column;
}

.header-main>p {
  font-size: 0.95rem;
  color: #555;
}

.header-main strong {
  font-weight: 400;
}

.calendar-section-container .MonthSection {
  display: block;
  padding: 1.1rem 1rem;
  border-bottom: 1px solid lightgray;
  position: sticky;
  top: 0px;
  z-index: 1;

  background-color: #fff;
}

.calendar-section-container h3 {
  font-size: 1.25rem;
}

@media screen and (max-width: 1024px) {
  .calendar-section-container .MonthSection {
    top: 0;
  }
}

.month-container {
  display: grid;
  box-sizing: border-box;
  grid-template-columns: repeat(7, 1fr);
  background-color: lightgray;
  border-bottom: 1px solid lightgray;
  gap: 1px;
  transition: all 0.3s;
}

.month-container .dayPlaceholder {
  background-color: whitesmoke;
}

@media screen and (max-width: 1024px) {
  .month-container {
    grid-template-columns: repeat(5, 1fr);
  }

  .month-container .dayPlaceholder {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .month-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 500px) {
  .month-container {
    grid-template-columns: repeat(1, 1fr);
  }

  .month-container button img {
    align-self: center;
    height: 2rem;
    width: 2rem;
  }
}

.month-container button {
  box-sizing: border-box;
  font-family: inherit;
  min-height: 112px;
  height: 100%;
  width: 100%;

  text-align: left;
  background-color: white;
  border: none;
  margin: 0;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media (hover: hover) {
  .month-container button:hover {
    background-color: #e4eeff;
    cursor: pointer;
  }

  .month-container button:hover h3,
  .month-container button:hover p {
    color: #005eca;
  }
}

.month-container button div {
  height: 100%;
  min-height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.month-container button h3 {
  font-size: 1.2rem;
  font-weight: 400;
}

.month-container button p {
  font-size: 1.1rem;
  font-weight: 400;
  color: #555;
}

.month-container button aside {
  color: #666;
  font-weight: 500;
  font-size: 0.9rem;
}

.month-container button img {
  width: 1rem;
  height: 1rem;
  bottom: 0;
  opacity: 0.7;
}

.modal-grid {
  display: grid;
  gap: 4rem;
  grid-template-columns: 0.6fr 1fr;
  height: 100%;
  overflow-y: initial !important;
}

.modal-grid h1 {
  font-size: 2.5rem;
  font-weight: 600;
}

.modal-grid p {
  font-size: 1.1rem;
  line-height: 1.5;
}

.modal-grid hr {
  border: none;
  border-top: 1px solid lightgray;
  margin-top: 1rem;
  padding-top: 1rem;
}

@media screen and (max-width: 1024px) {
  .modal-grid {
    grid-template-columns: 1fr;
    gap: 0;
  }
}

@media screen and (max-width: 768px) {
  .modal-grid {
    gap: 0;
  }

  .modal-grid h1 {
    font-size: 2rem;
  }
}

.walk-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100%;
  overflow-y: scroll;
}

.walk-grid h1 {
  font-size: 2.5rem;
  font-weight: 600;
}

.walk-grid hr {
  border: none;
  border-top: 1px solid lightgray;
  margin-top: 1rem;
  padding-top: 1rem;
}

.walk-grid p {
  font-size: 1.1rem;
  line-height: 1.5;
}

@media screen and (max-width: 1024px) {
  .walk-grid {
    grid-template-columns: 1fr;
    gap: 0;
  }
}

@media screen and (max-width: 768px) {
  .walk-grid {
    gap: 0;
  }

  .walk-grid h1 {
    font-size: 2rem;
  }
}

.walk-heading {
  border-bottom: 1px solid lightgray;
  padding: 1.5rem;

  display: flex;

  width: 100%;
  box-sizing: border-box;
}

.walk-heading h1 {
  flex: 1;
  font-size: 2rem;
  align-self: center;
}

.WalkBox {
  box-sizing: border-box;
  font-family: inherit;
  min-height: 112px;
  height: 100%;
  width: 100%;
  flex: 1;
  min-width: 320px;

  text-align: left;
  background-color: white;
  border: none;
  margin: 0;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.WalkBox h3 {
  font-size: 1.2rem;
  font-weight: 400;
  margin-bottom: 0.25rem;
}

.WalkBox p {
  font-size: 1rem;
  color: #333;
}

.WalkBox section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  min-height: inherit;
}

.WalkBox .Chevron {
  width: 1.25rem;
  height: 1.25rem;
  align-self: center;
}

@media (hover: hover) {
  .WalkBox:hover {
    background-color: #e4eeff;
    cursor: pointer;
  }

  .WalkBox:hover h3,
  .WalkBox:hover p {
    color: #005eca;
  }
}

.VaccineCentre {
  padding-bottom: 1.5rem;
  margin-bottom: 1.5rem;
  border-bottom: 2px solid lightgray;
}

.VaccineCentre h3 {
  font-size: 1.75rem;
  font-weight: 400;
  margin-bottom: 0.5rem;
}

.VaccineCentre .ButtonConstraint {
  display: flex;
  flex-wrap: wrap;

  margin: 1rem 0;
  gap: 1.5rem;
}

.VaccineCentre .ButtonConstraint a {
  flex: 1;
}

@media screen and (max-width: 768px) {
  .VaccineCentre h3 {
    font-size: 1.5rem;
    margin-bottom: 0.25rem;
  }
}

.VaccineCentre section.slot {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.VaccineCentre section.slot p {
  border-radius: 4px;
  border: 1px solid #e8e8e8;
  color: black;
  font-weight: 600;
  font-size: 1rem;
  padding: 0.5rem;
  min-width: 80px;
  text-align: center;
}

@media screen and (max-width: 500px) {
  .VaccineCentre section.slot p {
    flex: 1;
  }
}

@media screen and (max-width: 600px) {
  .VaccineCentre section.slot .ButtonConstraint {
    flex-direction: column;
    gap: 1rem;
  }
}