body {
  margin: 0;
  font-family: "Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bg-impt {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  background-repeat: no-repeat, no-repeat;
  background-position: center center, center center;
  background-size: cover, cover;
  z-index: -2 !important;
  background-image: url('./bg.svg');
}

.background {
  z-index: -2 !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

h2 {
  font-size: 1.5rem;
  font-weight: 600;
}

h2 strong {
  font-weight: 400;
  font-style: normal;
}

h3 {
  font-size: 1.2rem;
  font-weight: 600;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  outline: none;
  text-decoration: none;
  word-break: break-word;
}

.menu-header {
  max-width: 1488px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
}

.menu-divider {
  gap: 2.5rem;
  display: flex;
  align-self: center;
}

.menu-link {
  color: #000;
  font-size: 1.1rem;
  text-align: center;
  white-space: nowrap;
  align-self: center;
}

.menu-link:hover {
  color: blue;
  font-size: 1.1rem;
}

.menu-logo {
  font-size: 1.2rem;
  font-weight: 600;
  align-self: center;
}

.App-header {
  color: black;
  max-width: 1440px;
  margin: 0 auto;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.header-divider {
  height: 100%;
  width: 100%;
  background-color: lightgray;
  border: none;
}

.header-content {
  padding: 2.5rem;
}

.App-header h1 {
  font-size: 3rem;
}

.App-header h2 {
  margin-top: 0.5rem;
  font-size: 1.5rem;
}

.App-header p {
  font-size: 1.1rem;
  max-width: 944px;
  line-height: 1.5;
}

.header-img-container {
  max-height: 280px;
  overflow: hidden;
  display: block;
  margin: auto auto 0 auto;
}

.header-img {
  height: 300px;
  width: 340.66px;
  display: block;
  margin: 35px auto 0 auto;
  opacity: 0.7;
}

.clickable {
  background-color: white;
  cursor: pointer;
  font-size: 1rem;
  font-family: inherit;
}

.MobileOnly {
  display: none;
}

@media screen and (max-width: 768px) {
  .App-header h1 {
    font-size: 2rem;
  }
  .App-header h2 {
    font-size: 1.25rem;
  }
  .App-header p {
    font-size: 1rem;
  }
  .MobileOnly {
    display: block;
  }
}

@media screen and (max-width: 740px) {
  .App-header {
    grid-template-columns: 1fr;
  }
  .menu-link {
    display: none;
  }
  .header-img-container {
    display: none;
  }
  .header-content {
    padding: 1.5rem;
  }
  .header-content h1 {
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 500px) {
  .App-header {
    grid-template-columns: 1fr;
  }
  .header-content {
    padding: 1.5rem;
  }
  .header-content h1 {
    font-size: 2rem;
  }
}

.ModalHeader {
  position: sticky;
  top: 1.5rem;
  display: block;
}

.modal-container {
  overflow-y: scroll;
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
}

.modal-container::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}

.footer-header {
  text-align: center;
  padding: 2.5rem 2.5rem 4rem 2.5rem;
  line-height: 1.5;
}
